<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <div style="height: 550px;overflow-y: auto">

          <a-divider orientation="left">粘贴一键识别</a-divider>
          <a-textarea
              v-model="pasteText"
              :placeholder="placeholder"
              :auto-size="{ minRows: 5, maxRows: 10 }"
          />
          <a-divider orientation="left">基本信息</a-divider>
          <a-form-item label="托运人" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['shipper_id']"
                placeholder="请选择托运人"
            >
              <a-select-option v-for="item in shipper_list" :value="item.shipper_id">
                {{ item.shipper_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="客户" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['customer_id']"
                placeholder="请选择客户"
            >
              <a-select-option v-for="item in customer_list" :value="item.customer_id">
                {{ item.customer_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="计划备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-textarea
                :auto-size="{ minRows: 5, maxRows: 10 }"
                v-decorator="['plan_remarks']"
                placeholder="请输入计划备注"
            />
            <MultipleUpload
                :file="record.plan_remarks_file_list ? record.plan_remarks_file_list : []"
                v-decorator="['plan_remarks_file']"
            />
          </a-form-item>
          <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-radio-group
                v-decorator="['status',{initialValue:0}]"
            >
              <a-radio :value="0">待完善</a-radio>
              <a-radio :value="1">进行中</a-radio>
              <!--              <a-radio :value="1">已完成</a-radio>-->
            </a-radio-group>
          </a-form-item>


          <a-divider orientation="left">装卸信息</a-divider>
          <a-button @click="handleAddLoadUnload">添加一项</a-button>

          <div v-for="(item,index) in loadUnloadList" class="loadUnload">
            <div style="text-align: right">
              <a style="color: red" @click="loadUnloadList.splice(index,1)">删除</a>
            </div>
            <a-row>
              <a-col :span="12">
                <a-form-item label="装货单号" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-input
                      placeholder="请输入装货单号"
                      v-model="item.zhdh"
                  />
                </a-form-item>
                <a-form-item label="装货地址" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.zhdz"
                      placeholder="请选择装货地址"
                  >
                    <a-select-option v-for="item in address_list" :value="item.address_id">
                      {{ item.address_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="装货品名" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.zhpm"
                      placeholder="请选择装货品名"
                  >
                    <a-select-option v-for="(item,index) in goods_list" :value="item.goods_id">
                      {{ item.goods_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="装货数量" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-input
                      placeholder="请输入装货数量"
                      v-model="item.zhsl"
                  />
                </a-form-item>
                <a-form-item label="装货罐号" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-input
                      placeholder="请输入装货罐号"
                      v-model="item.zhgh"
                  />
                </a-form-item>
                <a-form-item label="装货货主" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-select
                      allowClear
                      show-search
                      :filter-option="(input,o)=>{
                        handleConductive(input,o,index,item)
                      }"
                      v-model="item.zhhz"
                      placeholder="请选择装货货主"
                  >
                    <a-select-option v-for="item in cargoowner_list" :value="item.cargoowner_id">
                      {{ item.cargoowner_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="联系方式" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-input
                      placeholder="请输入装货联系方式"
                      v-model="item.zhlxfs"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="卸货单号" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-input
                      placeholder="请输入卸货单号"
                      v-model="item.xhdh"
                  />
                </a-form-item>
                <a-form-item label="卸货地址" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.xhdz"
                      placeholder="请选择卸货地址"
                  >
                    <a-select-option v-for="item in address_list" :value="item.address_id">
                      {{ item.address_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="卸货品名" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.xhpm"
                      placeholder="请选择卸货品名"
                  >
                    <a-select-option v-for="item in goods_list" :value="item.goods_id">
                      {{ item.goods_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="卸货数量" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-input
                      placeholder="请输入卸货数量"
                      v-model="item.xhsl"
                  />
                </a-form-item>
                <a-form-item label="卸货罐号" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-input
                      placeholder="请输入卸货罐号"
                      v-model="item.xhgh"
                  />
                </a-form-item>
                <a-form-item label="卸货货主" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.xhhz"
                      placeholder="请选择卸货货主"
                  >
                    <a-select-option v-for="item in cargoowner_list" :value="item.cargoowner_id">
                      {{ item.cargoowner_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="联系方式" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
                  <a-input
                      placeholder="请输入卸货联系方式"
                      v-model="item.xhlxfs"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <a-divider orientation="left">调度车辆</a-divider>

          <template v-if="mode=='add'">
            <a-form-item label="选择车辆（支持多选）" :labelCol="labelCol" :wrapperCol="orderWrapperCol">
              <a-select
                  allowClear
                  mode="multiple"
                  @change="rendVehicleTabel"
                  show-search
                  :filter-option="filterOption"
                  v-decorator="['vehicle_id',{rules: [{required: true, message: '请选择车辆'}]}]"
                  placeholder="请选择车辆"
              >
                <a-select-option v-for="item in vehicle_list" :value="item.vehicle_id" :item="item">
                  {{ item.vehicle_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="table" v-if="!isEmpty(form.getFieldValue('vehicle_id'))">
              <div class="tr">
                <div class="td">牵引车车号</div>
                <div class="td">挂车车号</div>
                <div class="td">主班驾驶员</div>
                <div class="td">副班驾驶员</div>
                <div class="td">押运员</div>
                <div class="td">装货趟数</div>
              </div>
              <div class="tr" v-for="item in vehicleTableList">
                <div class="td">{{ item.cl.xsz_hphm }}</div>
                <div class="td">{{ item.gc.xsz_hphm }}</div>
                <div class="td">
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.driver_id"
                  >
                    <a-select-option v-for="item in driver_list" :value="item.personnel_id">
                      {{ item.personnel_name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="td">
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.driver_id2"
                  >
                    <a-select-option v-for="item in driver_list" :value="item.personnel_id">
                      {{ item.personnel_name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="td">
                  <a-select
                      allowClear
                      show-search
                      :filter-option="filterOption"
                      v-model="item.supercargo_id"
                  >
                    <a-select-option v-for="item in supercargo_list" :value="item.personnel_id">
                      {{ item.personnel_name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="td">
                  <a-input-number v-model="item.number" :min="1" :max="999"/>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <span style="color:red;">当前订单已调度车辆，如需修改请前往列表<a>已调度车辆</a></span>
          </template>

        </div>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/order'
import {MultipleUpload, UploadFile} from '@/components'
import _ from "lodash";
import {filterParams, isEmpty} from "@/utils/util";

export default {
  components: {
    MultipleUpload,
    UploadFile
  },
  props: {
    // 地址列表
    address_list: {
      type: Array,
      default: []
    },
    // 托运人列表
    shipper_list: {
      type: Array,
      default: []
    },
    // 车辆列表
    vehicle_list: {
      type: Array,
      default: []
    },
    // 品名列表
    goods_list: {
      type: Array,
      default: []
    },
    // 货主列表
    cargoowner_list: {
      type: Array,
      default: []
    },
    // 客户列表
    customer_list: {
      type: Array,
      default: []
    },
    // 司机列表
    driver_list: {
      type: Array,
      default: []
    },
    // 押运员列表
    supercargo_list: {
      type: Array,
      default: []
    },
  },
  watch: {
    pasteText(newValue, oldValue) {
      this.handleAutofill(newValue)
    }
  },
  data() {
    return {
      pasteText: '',

      // 对话框标题
      title: '创建订单',
      // 标签布局属性
      labelCol: {
        span: 4
      },
      // 输入框布局属性
      wrapperCol: {
        span: 18
      },
      orderWrapperCol: {
        span: 20
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",
      // 车辆表格列表
      vehicleTableList: [],
      // 装卸信息
      loadUnloadList: [
        {
          // zhdh: '',
          // zhdz: '',
          // zhpm: '',
          // zhsl: '',
          // zhgh: '',
          // zhhz: '',
          // zhlxfs: '',
          //
          // xhdh: '',
          // xhdz: '',
          // xhpm: '',
          // xhsl: '',
          // xhgh: '',
          // xhhz: '',
          // xhlxfs: '',
        }
      ],

      placeholder: ""
    }
  },
  created() {
    this.placeholder =
        "将文本粘贴此处，自动填充表单信息\n" +
        "示例：\n\n" +
        "托运人：测试托运人\n" +
        "客户：D客户\n" +
        "计划备注：XXXXXX备注\n" +


        "装货单号[1]：百安联兴\n" +
        "卸货单号[1]：zt卸货单号\n" +

        "装货地址[1]：百安石化联兴库\n" +
        "卸货地址[1]：九丰能源\n" +

        "装货品名[1]：测试货2\n" +
        "卸货品名[1]：测试货3\n" +

        "装货数量[1]：100\n" +
        "卸货数量[1]：98\n" +

        "装货罐号[1]：705-906\n" +
        "卸货罐号[1]：88888\n" +

        "装货货主[1]：货主A\n" +
        "卸货货主[1]：货主B\n" +

        "装货联系方式[1]：15000000000\n" +
        "卸货联系方式[1]：13000000000"

    // this.pasteText = this.placeholder

  },
  methods: {
    handleConductive(input, option, index, field) {
      console.log(input, option, index, field)
      this.loadUnloadList[index] = field

      console.log(this.loadUnloadList)
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0

    },
    isEmpty,
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    handleAutofill(value) {
      const list = value.split('\n')
      list.forEach((item) => {
        const value = item.split('：')

        // 查找托运人
        if (value[0] && value[0] === '托运人' && value[1]) {
          const shipper = this.shipper_list.find(item => item.shipper_name == value[1])
          if (shipper) {
            this.form.setFieldsValue({shipper_id: shipper.shipper_id})
          }
        }

        // 查找客户
        if (value[0] && value[0] === '客户' && value[1]) {
          const customer = this.customer_list.find(item => item.customer_name == value[1])
          if (customer) {
            this.form.setFieldsValue({customer_id: customer.customer_id})
          }
        }

        // 计划备注
        if (value[0] && value[0] === '计划备注' && value[1]) {
          this.form.setFieldsValue({plan_remarks: value[1]})
        }

        if (value[0] && value[0].indexOf('装货单号') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            this.loadUnloadList[index].zhdh = value[1]
          }
        }

        if (value[0] && value[0].indexOf('卸货单号') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            this.loadUnloadList[index].xhdh = value[1]
          }
        }

        if (value[0] && value[0].indexOf('装货地址') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            const zhdz = this.address_list.find(item => item.address_name == value[1])
            if (zhdz) {
              this.loadUnloadList[index].zhdz = zhdz.address_id
            }
          }
        }

        if (value[0] && value[0].indexOf('卸货地址') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            const xhdz = this.address_list.find(item => item.address_name == value[1])
            if (xhdz) {
              this.loadUnloadList[index].xhdz = xhdz.address_id
            }
          }
        }

        if (value[0] && value[0].indexOf('装货品名') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            const zhpm = this.goods_list.find(item => item.goods_name == value[1])
            if (zhpm) {
              this.loadUnloadList[index].zhpm = zhpm.goods_id
            }
          }
        }

        if (value[0] && value[0].indexOf('卸货品名') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            const item = this.goods_list.find(item => item.goods_name == value[1])
            if (item) {
              this.loadUnloadList[index].xhpm = item.goods_id
            }
          }
        }

        if (value[0] && value[0].indexOf('装货数量') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            this.loadUnloadList[index].zhsl = value[1]
          }
        }

        if (value[0] && value[0].indexOf('卸货数量') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            this.loadUnloadList[index].xhsl = value[1]
          }
        }

        if (value[0] && value[0].indexOf('装货罐号') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            this.loadUnloadList[index].zhgh = value[1]
          }
        }

        if (value[0] && value[0].indexOf('卸货罐号') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            this.loadUnloadList[index].xhgh = value[1]
          }
        }


        if (value[0] && value[0].indexOf('装货货主') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            const item = this.cargoowner_list.find(item => item.cargoowner_name == value[1])
            if (item) {
              this.loadUnloadList[index].zhhz = item.cargoowner_id
            }
          }
        }

        if (value[0] && value[0].indexOf('卸货货主') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            const item = this.cargoowner_list.find(item => item.cargoowner_name == value[1])
            if (item) {
              this.loadUnloadList[index].xhhz = item.cargoowner_id
            }
          }
        }

        if (value[0] && value[0].indexOf('装货联系方式') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            this.loadUnloadList[index].zhlxfs = value[1]
          }
        }

        if (value[0] && value[0].indexOf('卸货联系方式') !== -1 && value[1]) {
          const indexArray = value[0].match(/(?<=\[)(.+?)(?=\])/g)
          //不存在默认第一个
          const index = !isEmpty(indexArray) && indexArray[0] ? (Number(indexArray[0]) - 1 >= 0 ? Number(indexArray[0]) - 1 : 0) : 0
          if (this.loadUnloadList[index]) {
            this.loadUnloadList[index].xhlxfs = value[1]
          }
        }
      })
    },


    /**
     * 渲染车辆表格
     */
    rendVehicleTabel(values, option) {
      const list = []
      option.map(item => list.push({...item.data.attrs.item, ...{number: 1}}))
      this.vehicleTableList = list
    },

    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '创建订单'
      this.visible = true
    },

    /**
     * 编辑
     */
    edit(record) {
      this.mode = "edit"
      this.title = '编辑订单'
      this.visible = true

      // 过滤为空的值
      this.record = filterParams(record)
      this.setFieldsValue()
    },

    /**
     * 增加装卸地址
     */
    handleAddLoadUnload() {
      this.loadUnloadList.push({})
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['shipper_id', 'customer_id', 'plan_remarks', 'status'])
        setFieldsValue(data)
        this.loadUnloadList = this.record.loadunload_list || []
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true

      values['vehicle_list'] = this.vehicleTableList
      values['loadUnloadList'] = this.loadUnloadList

      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({orderId: this.record['order_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>

<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    height: 40px;
    line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      height: 40px;
      width: 100%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        border: none;
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}

.loadUnload {
  margin-top: 10px;
  border: 1px #00a0e9 dashed;
  padding: 10px;
  background: #f9f9f9;
}
</style>
