<template>
  <a-row :gutter="10" style="margin-top: 10px">
    <a-form :layout="formLayout" labelAlign="left" :form="searchForm" @submit="handleSubmit">
      <template v-if="orderStatus">
        <a-col :span="6">
          <a-form-item label="订单状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                v-decorator="['status']"
                :options="[{ label: '待完善', value: 0 },{ label: '进行中', value: 1 }]"
                placeholder="请选择订单状态"
            />
          </a-form-item>
          <a-form-item label="装货地址" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['start_address_id']"
                placeholder="请选择装货地址"
            >
              <a-select-option v-for="item in address_list" :value="item.address_id">
                {{ item.address_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="所属客户" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['customer_id']"
                placeholder="请选择所属客户"
            >
              <a-select-option v-for="item in customer_list" :value="item.customer_id">
                {{ item.customer_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="卸货地址" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['end_address_id']"
                placeholder="请选择卸货地址"
            >
              <a-select-option v-for="item in address_list" :value="item.address_id">
                {{ item.address_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="派单时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-range-picker
                format="YYYY-MM-DD"
                v-decorator="['create_time']"
            />
          </a-form-item>

        </a-col>
      </template>
      <template v-else>
        <a-col :span="6">
          <a-form-item label="所属客户" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['customer_id']"
                placeholder="请选择所属客户"
            >
              <a-select-option v-for="item in customer_list" :value="item.customer_id">
                {{ item.customer_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="装货地址" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['start_address_id']"
                placeholder="请选择装货地址"
            >
              <a-select-option v-for="item in address_list" :value="item.address_id">
                {{ item.address_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="卸货地址" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-select
                allowClear
                show-search
                :filter-option="filterOption"
                v-decorator="['end_address_id']"
                placeholder="请选择卸货地址"
            >
              <a-select-option v-for="item in address_list" :value="item.address_id">
                {{ item.address_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </template>
      <a-col :span="6">
        <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-button style="margin-right: 10px" type="primary" html-type="submit" icon="search">搜索</a-button>
          <a-button @click="handleReset">重置表单</a-button>
        </a-form-item>
      </a-col>

    </a-form>
  </a-row>
</template>
<script>
import {SelectRegion} from '@/components'

export default {
  name: 'searchForm',
  props: {
    // 地址列表
    address_list: {
      type: Array,
      default: []
    },
    // 托运人列表
    shipper_list: {
      type: Array,
      default: []
    },
    // 车辆列表
    vehicle_list: {
      type: Array,
      default: []
    },
    // 品名列表
    goods_list: {
      type: Array,
      default: []
    },
    // 货主列表
    cargoowner_list: {
      type: Array,
      default: []
    },
    // 客户列表
    customer_list: {
      type: Array,
      default: []
    },

    // 订单状态筛选
    orderStatus: {
      type: Boolean,
      default: true
    },// 订单状态筛选
    showCreateTime: {
      type: Boolean,
      default: true
    },
  },
  components: {
    SelectRegion
  },
  data() {
    return {
      searchForm: this.$form.createForm(this),
      queryParam: {},
      formLayout: 'horizontal',
      labelCol: {
        style: 'width: 70px;float:left;margin-right:10px;white-space: nowrap;\n' +
            'text-overflow: ellipsis;\n' +
            '-o-text-overflow: ellipsis;\n' +
            'overflow: hidden;'
      },
      wrapperCol: {style: 'width: calc( 95% - 70px );float:left'},
    }
  },
  methods: {
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    handleSubmit(e) {
      e.preventDefault()
      const {searchForm: {validateFields}} = this
      validateFields((errors, values) => {
        if (errors) {
          return false
        }
        this.$emit('handleSubmit', values)
      })
    },
    handleReset() {
      this.searchForm.resetFields()
    }
  }
}
</script>
<style scoped lang="less">

</style>
